import {
  outsideWindow,
  // automaticLocks,
  kitchenWindow,
  frontHouseView,
} from "../assets";

import { features } from "../constants";

import styles from "../style";

const Features = () => (
  <section id="features" className="relative">
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 lg:max-w-7xl ">
        <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
          <div>
            <div className="border-b border-gray-200 ">
              <span className="font-poppins font-normal xs:text-[20px] text-[15px] xs:leading-[26px] leading[21px] uppercase ml-3">
                Features
              </span>
              <h2 className={`${styles.heading2}`}>
                <span className="flex flex-col items-left bg-lightBlue-gradient">
                  Technical{" "}
                  <span className="hidden sm:inline">Specification</span>
                  <span className=" inline sm:hidden">Specs</span>
                </span>
              </h2>
            </div>

            <dl className="mt-10 space-y-10 font-poppins">
              {features.map((feature) => (
                <div key={feature.name}>
                  <dt className="text-[18px] font-medium text-gray-900">
                    {feature.name}
                  </dt>
                  <dd className="mt-3 text-[16px] text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div>
            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
              <img
                src={outsideWindow}
                alt="outsideWindow"
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
                <img
                  src={kitchenWindow}
                  alt="kitchenWindow"
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
                <img
                  src={frontHouseView}
                  alt="frontHouseView"
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Features;
