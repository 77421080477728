import {
  facebook,
  instagram,
  linkedin,
  twitter,
  airbnb,
  binance,
  coinbase,
  dropbox,
  send,
  shield,
  star,
} from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "about-us",
    title: "About Us",
  },
  {
    id: "features",
    title: "Features",
  },
  {
    id: "benefits",
    title: "Benefits",
  },
  {
    id: "gallery",
    title: "Gallery",
  },
  {
    id: "testimonials",
    title: "Testimonials",
  },
  {
    id: "faq",
    title: "FAQ",
  },
  {
    id: "contact-us",
    title: "Contact Us",
  },
];

export const purchaseAndInstallationSteps = [
  {
    id: "01.",
    title: "Free Measurement",
    description:
      "Book a free measurement, and our expert will take accurate measurements and give you a quote.",
  },
  {
    id: "02.",
    title: "Own Production",
    description:
      "Your custom windows and doors are made in-house with high-quality materials and craftsmanship.",
  },
  {
    id: "03.",
    title: "Quick installation",
    description:
      "We’ll arrange installation and complete it efficiently, ensuring your home is respected and codes are met.",
  },
  {
    id: "04.",
    title: "Huge experience",
    description:
      "Enjoy your new windows and doors, designed to last and enhance your space for years.",
  },
];

export const features = [
  {
    name: "Automatic Locks",
    description:
      "Automatic locks for horizontal and vertical hung windows close and lock with one simple click.",
  },
  {
    name: "Metal Rollers",
    description:
      "All metal window rollers provide durable and long lasting operation.",
  },
  {
    name: "Vertical Single Hung",
    description:
      "The one button control has a digital readout for setting temperature and turning the kettle on and off.",
  },
  {
    name: "Weep holes",
    description: "Weep holes prevent debris obstruction",
  },
];

export const benefits = [
  {
    id: "feature-1",
    icon: star,
    title: "Comprehensive Support",
    content:
      "Complete assistance from selection to installation for renovations or new projects, ensuring a hassle-free experience.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Lifetime Warranty Assurance",
    content:
      "Lifetime warranty on windows and doors, ensuring lasting satisfaction and confidence in your home improvement journey.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Energy Efficiency and Savings",
    content:
      "Energy-efficient products to lower your energy bills while maintaining comfort and style.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Very smooth experience. Very transparent and friendly. They have in depth knowledge of city code. ",
    name: "Tejas D.",
  },
  {
    id: "feedback-2",
    content:
      "The entire process was suprisingly easy with great communication throughout. Pricing and work quality superb.",
    name: "Melinda K.",
  },
  {
    id: "feedback-3",
    content:
      "I had this company replace 10 windows and a sliding door. Not only did they beat everyone else's prices but the windows are good quality and their installers did all of it in one day.",
    name: "Leonel M.",
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "Projects Completed",
    value: "1200+",
  },
  {
    id: "stats-2",
    title: "Satisfied Customers",
    value: "1000+",
  },
  {
    id: "stats-3",
    title: "Years of Experience",
    value: "10+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.hoobank.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.hoobank.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.hoobank.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.hoobank.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.hoobank.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.hoobank.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.hoobank.com/suggestions/",
      },
      {
        name: "Blog",
        link: "https://www.hoobank.com/blog/",
      },
      {
        name: "Newsletters",
        link: "https://www.hoobank.com/newsletters/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.hoobank.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.hoobank.com/become-a-partner/",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];

export const faqs = [
  {
    question: "What kind of windows do you offer?",
    answer:
      "We offer energy-efficient, double-pane windows made from high-quality materials.",
  },
  {
    question: "Do you provide financing?",
    answer: "Yes, we provide flexible financing options for our customers.",
  },
  {
    question: "What is your lead time for manufacturing?",
    answer:
      "Standard windows are ready in 4-5 days, while custom designs take 2-3 weeks.",
  },
  {
    question: "How long does installation take?",
    answer:
      "Installation typically takes 5-10 hours, with most jobs completed in one day. However, there are exceptions for more complex projects.",
  },
];

export const galleryPhotos = [
  {
    id: "photo-1",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/AST_multislide-black-expansive-open.webp/:/",
  },

  {
    id: "photo-2",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/genMid.NP23209427_32_0.jpg/:/",
  },
  {
    id: "photo-3",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/NP23209427_5_1.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:740,cg:true",
  },
  {
    id: "photo-4",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/bifold%20black.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:426,cg:true",
  },
  {
    id: "photo-5",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/bifold%20black%202.jpeg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-6",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/PW23130200_64_0.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-7",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/bifold%20black%203.jpeg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:431,cg:true",
  },
  {
    id: "photo-8",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/NP23209427_24_0.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-9",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/2018-08-25%2010.54.19.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-10",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/2019-01-10%2013.13.25.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-11",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/2018-12-28%2009.54.03.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-12",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/2018-12-12%2014.53.22.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-13",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/2019-01-10%2013.32.53.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-14",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/2018-12-12%2014.53.32.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-15",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/2019-01-10%2013.33.44.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-16",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/2019-01-10%2014.22.30.jpg/:/rs=w:740,cg:true,m",
  },
  {
    id: "photo-17",
    picUrl:
      "https://img1.wsimg.com/isteam/ip/e613bd89-f6ee-456b-ada8-7a8d1fc9671b/2021-02-18%2007.52.21.jpg/:/rs=w:740,cg:true,m",
  },
];
