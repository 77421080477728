import styles from "../style";
import { discount, starbridgeHeroPic } from "../assets";
import Button from "../components/Button";
import "./Hero.css";

import { motion } from "framer-motion";

const Hero = () => {
  return (
    <section
      id="home"
      className={`flex md:flex-row flex-col ${styles.paddingY}`}
    >
      <motion.div
        variants={{
          hidden: { opacity: 0, x: -95 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1, delay: 0.25 }}
        className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}
      >
        <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2">
          <img src={discount} alt="discount" className="w-[32-px] h-[32px]" />
          <p className={`${styles.paragraph}`}>
            <span className="text-white"> 10% </span> Discount until{" "}
            <span className="text-white"> October 1st </span>
          </p>
        </div>

        <div className="flex-1 flex-row justify-between items-center w-full">
          <h1 className="flex-1 font-poppins font-semibold xs:text-[42px] ss:text-[72px] text-white ss:leading-[100px] leading-[75px]">
            <span className="text-gradient"> Transform </span>
          </h1>
        </div>

        <h1 className="font-poppins font-semibold xs:text-[42px] ss:text-[68px] text [52px] text-white ss:leading-[100px] leading-[75px] w-full">
          Your Home's
          <br className="xs:block hidden" />
          Exterior Today
        </h1>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          We combine innovative design with expert installation, utilizing
          premium materials and precise craftsmanship to deliver outstanding
          results.
        </p>
        <br />
        <div className=" xs:mr-4 mr-0">
          <a href="#contact-us">
            <Button />
          </a>
        </div>
      </motion.div>

      <motion.div
        variants={{
          hidden: { opacity: 0, x: 95 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1, delay: 0.25 }}
        className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative `}
      >
        <img
          src={starbridgeHeroPic}
          alt="billing"
          className="w-[100%] h-[100%] relative z-[5] absolute inset-0 w-full h-full object-cover shadow-[5px_5px_50px_1px]"
        />
        <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="sm:absolute z-[2] w-[80%] h-[80%] rounded-full bottom-40 white__gradient " />
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 top-0 bottom-20 blue__gradient" />
      </motion.div>
    </section>
  );
};

export default Hero;
