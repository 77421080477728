import styles from "./style";

import Reveal from "./components/Reveal";

import {
  Navbar,
  Stats,
  Benefits,
  Testimonials,
  CTA,
  Footer,
  Hero,
  AboutUs,
  Features,
  FAQs,
  Gallery,
} from "./components";

const App = () => {
  return (
    <div className="w-full overflow-hidden">
      {/* Navbar Component */}
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>

      {/* Hero Component */}
      <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Hero />
        </div>
      </div>

      {/* Stats Component */}
      <Reveal>
        <div className={` ${styles.paddingX} ${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <Stats />
          </div>
        </div>
      </Reveal>

      {/* AboutUs Component */}
      <Reveal>
        <div
          className={` ${styles.paddingX} ${styles.flexStart} bg-cover bg-center relative`}
        >
          <div className={`${styles.boxWidth}`}>
            <AboutUs />
          </div>
        </div>
      </Reveal>

      {/* Features Component */}
      <Reveal>
        <div
          className={` pt-[3rem] pb-[6rem] sm:py-12${styles.paddingX} ${styles.flexStart}`}
        >
          <div className={`${styles.boxWidth}`}>
            <Features />
          </div>
        </div>
      </Reveal>

      {/* Benefits Component */}
      <Reveal>
        <div
          className={`bg-primary py-[4rem] ${styles.paddingX} ${styles.flexStart}`}
        >
          <div className={`${styles.boxWidth}`}>
            <Benefits />
          </div>
        </div>
      </Reveal>

      {/* Gallery Component */}
      <Reveal>
        <div
          className={`bg-primary py-[2rem] ${styles.paddingX} ${styles.flexStart}`}
        >
          <div className={`${styles.boxWidth}`}>
            <Gallery />
          </div>
        </div>
      </Reveal>

      {/* Testimonials Component */}
      <Reveal>
        <div className={` py-[4rem] ${styles.paddingX} ${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <Testimonials />
          </div>
        </div>
      </Reveal>

      {/* FAQs Component */}
      <Reveal>
        <div className={`${styles.paddingX} ${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <FAQs />
          </div>
        </div>
      </Reveal>

      {/* CTA Component */}
      <Reveal>
        <div className={`pt-[8rem] ${styles.paddingX} ${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <CTA />
          </div>
        </div>
      </Reveal>

      {/* Footer Component */}
      <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
