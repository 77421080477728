import { stats } from "../constants";
import styles from "../style";

const Stats = () => (
  <section
    className={`${styles.flexCenter} flex-row flex-wrap pb-[4rem] sm:pb-[0rem] sm:mb-0 sm:mb-6`}
  >
    {stats.map((stat) => (
      <div
        key={stat.id}
        className={`flex-1 flex justify-center items-center flex-row my-8 sm:m-3 `}
      >
        <h4 className="font-poppins font-semibold xs:text-[35px] text-[30px] xs:leading-[53px] leading[43px] ">
          {stat.value}
        </h4>
        <p className="font-poppins font-normal xs:text-[20px] text-[15px] xs:leading-[26px] leading[21px] uppercase ml-3 bg-lightBlue-gradient">
          {stat.title}
        </p>
      </div>
    ))}
  </section>
);

export default Stats;
