const PurchaseAndInstallationProcess = (step) => {
  const { id, title, description } = step.step;
  return (
    <div className="w-full md:w-1/4 p-8 box-border relative">
      <span className="block text-[120px] font-poppins font-bold text-light-blue opacity-[.06] absolute top-0 left-0 -z-10">
        {id}
      </span>
      <div className="text-[18px] font-medium">{title}</div>
      <p className="text-[16px]">{description}</p>
    </div>
  );
};

export default PurchaseAndInstallationProcess;
