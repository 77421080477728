import { useEffect, useState } from "react";
import { galleryPhotos } from "../constants";

const MasonryGrid = () => {
  return (
    <div className="columns-1 xs:h-[80vh] sm:h-[120vh] md:h-[80vh] overflow-x-scroll sm:columns-2 md:columns-3 lg:columns-3 gap-4 w-11/12">
      {galleryPhotos.length > 0 &&
        galleryPhotos.map((photo, index) => (
          <div key={index} className="mb-4 break-inside-avoid ">
            <img src={photo.picUrl} alt="" className="w-full rounded-lg" />
          </div>
        ))}
    </div>
  );
};

export default MasonryGrid;
