const styles = {
  boxWidth: "xl:max-w-[1280px] w-full",

  heading2:
    "font-poppins font-semibold xs:text-[50px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full pb-[1rem]",
  heading3:
    "font-poppins font-semibold xs:text-[50px] text-[40px] xs:leading-[76.8px] leading-[66.8px] w-full pb-[1rem]",
  paragraph:
    "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",

  paddingX: "sm:px-16 px-6",
  paddingY: "sm:py-11 py-4",
  padding: "sm:px-16 px-6 sm:py-12 py-4",

  marginX: "sm:mx-16 mx-6",
  marginY: "sm:my-16 my-6",
};

export const layout = {
  section: `flex md:flex-row flex-col ${styles.paddingY}`,
  sectionReverse: `flex flex-col-reverse h-3/4`, // <---- The following was inside: ${styles.paddingY}

  sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,
  sectionImgReverse: `h-webkitFillAvailable w-inherit h-full`,

  sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
  businessSectionInfo: `flex-1 ${styles.flexStart} flex-col `,
};

export default styles;
