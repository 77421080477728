import { feedback } from "../constants";
import styles from "../style";
import FeedbackCard from "./FeedbackCard";

const Testimonials = () => {
  return (
    <section
      id="testimonials"
      className={`${styles.paddingY} ${styles.flexCenter} py-10 flex-col relative`}
    >
      <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient" />

      <div className="w-full flex justify-between items-center md:flex-col flex-col mb-6 realtive z-[1]">
        <h2
          className={`${styles.heading2} flex justify-center bg-lightBlue-gradient`}
        >
          What people are <br className="sm:block hidden" />
          saying about us
        </h2>
      </div>
      <div className="w-9/12 border-b border-gray-200 mb-10 mx-36"></div>
      <div className="flex flex-wrap sm:justify-center jsutify-center w-full feedback-container relative z-[1]">
        {feedback.map((card) => (
          <FeedbackCard key={card.id} {...card} />
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
