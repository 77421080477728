import styles from "../style";
import { livingRoomForAboutUs } from "../assets";
import { purchaseAndInstallationSteps } from "../constants";
import PurchaseAndInstallationProcess from "./PurchaseAndInstallationProcess";
const AboutUs = () => {
  return (
    <section
      id="about-us"
      className={`${styles.flexCenter} flex-col md:flex-row md:flex-wrap sm:mb-0 mb-6 `}
    >
      {/* My About Us */}
      <div className="flex-1 flex justify-center flex-col md:grid grid-cols-2 md:gap-4">
        {/* Image */}
        <div className=" pt-[1rem] sm:pt-12 rounded-[20px] mr-0 my-5 order-2 md:order-1">
          <img
            className="rounded-tl-[100px] rounded-br-[100px] h-[530px] w-full "
            src={livingRoomForAboutUs}
            alt="livingRoomForAboutUs"
          />
        </div>

        {/* Text */}
        <div className="flex flex-col pt-6 md:px-10 sm:pt-12 rounded-[20px] mr-0 my-5 font-poppins order-1 md:order-2">
          <span className="font-normal xs:text-[20px] text-[15px] xs:leading-[26px] leading[21px] uppercase ml-3">
            About Our Company
          </span>
          <h2 className={`${styles.heading2} pb-[2.5rem] md:pb-1`}>
            <span className="flex flex-col md:items-center bg-lightBlue-gradient">
              Custom Windows And
              <br />
              Doors By Starbridge
            </span>
          </h2>
          <p className="font-poppins px-5 py-8 md:py-3 font-normal text-dimWhite text-[18px] leading-[30.8px] md:leading-[24px] mb-1 rounded-[20px] bg-[#334879]">
            {/* <b>Enhancing Homes with Quality and Care</b> */}
            For a decade, Starbridge Windows & Doors has transformed homes with
            energy-efficient windows and doors. We create beautiful, comfortable
            spaces, ensuring a seamless journey from consultation to
            installation.
            <br />
            {/* <br /> */}
            {/* <b>Our Commitment</b> */}
            <br />
            We deliver outstanding customer service, understanding your unique
            needs and recommending the perfect windows and doors. Our skilled
            installers guarantee flawless, efficient installations.
            <br />
            {/* <br /> */}
            {/* <b>Exceeding Expectations</b> */}
            <br />
            We aim to exceed expectations with products and services that
            enhance your home's aesthetics and functionality, ensuring lasting
            satisfaction.
          </p>
        </div>
      </div>

      {/* Step-by-step process */}
      <div className="pb-12 w-full max-w-screen-xl px-4 mx-auto flex flex-col md:flex-row justify-start flex-wrap font-poppins order-3">
        {/* Purchase and installation process */}
        {purchaseAndInstallationSteps.map((step, index) => {
          return <PurchaseAndInstallationProcess key={step.id} step={step} />;
        })}
      </div>
    </section>
  );
};

export default AboutUs;
