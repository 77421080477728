import styles from "../style";
import MasonryGrid from "./MasonryGrid";

const Gallery = () => {
  return (
    <section
      id="gallery"
      className={`${styles.paddingY} ${styles.flexCenter} flex-col relative text-center`}
    >
      {/* Header */}
      <h2 className={styles.heading2}>
        <span className="text-gradient">Gallery</span>
      </h2>

      <div className="w-9/12 border-b border-gray-200 mb-10"></div>

      {/* image content */}
      <MasonryGrid />
    </section>
  );
};

export default Gallery;
