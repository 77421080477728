import { CloudArrowUpIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import {
  emptyDoorLivingRoomInteriorBackground,
  homeIndoorDesignConcept,
} from "../assets";
import { layout } from "../style";
import "./LearnMore.css";

const products = [
  {
    name: "Windows",
    description:
      "Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.",
    img: `${homeIndoorDesignConcept} `,
    icon: CloudArrowUpIcon,
  },
  {
    name: "Doors",
    description:
      "Pellentesque enim a commodo malesuada turpis eleifend risus. Facilisis donec placerat sapien consequat tempor fermentum nibh.",
    img: `${emptyDoorLivingRoomInteriorBackground}`,
    icon: LockClosedIcon,
  },
  // {
  //   name: "Simple queues",
  //   description:
  //     "Pellentesque sit elit congue ante nec amet. Dolor aenean curabitur viverra suspendisse iaculis eget. Nec mollis placerat ultricies euismod ut condimentum.",
  //   href: "#",
  //   icon: ArrowPathIcon,
  // },
];

export default function LearnMore() {
  return (
    <section className={`pb-[2.25rem] ${layout.sectionReverse}`}>
      {/* <div className="mx-auto max-w-7xl px-6 lg:px-8"> */}
      {/* <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">
            Deploy faster
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Everything you need to deploy your app
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Quis tellus eget adipiscing convallis sit sit eget aliquet quis.
            Suspendisse eget egestas a elementum pulvinar et feugiat blandit at.
            In mi viverra elit nunc.
          </p>
        </div> */}
      <div className=" bg-gray-400 mx-auto max-w-2xl  lg:max-w-none bg-white px-11">
        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2 sm:py-8">
          {products.map((products) => (
            <div key={products.name} className="flex flex-col">
              <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white h-[404px]">
                {/* <products.icon
                    className="h-5 w-5 flex-none text-indigo-400"
                    aria-hidden="true"
                  /> */}
                <img
                  src={products.img}
                  alt="empty-door-living-room-interior-background"
                  className="LearnMore-Img"
                />
              </dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p className=" flex flex-col">
                  {products.name}
                  <a
                    href={products.href}
                    className="text-sm font-semibold leading-6 text-indigo-400"
                  >
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
      {/* </div> */}
      {/* <div className="absolute z-[3] -left-1/2 top-0 w-[50%] h-[60%] rounded-full white__gradient" /> */}
    </section>
  );
}
