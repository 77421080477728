import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import styles from "../style";
import { faqs } from "../constants";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function FAQs() {
  return (
    <section id="faq">
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 pt-[4rem] pb-[12rem] sm:py-20 lg:px-8 ">
          <div className=" divide-y divide-gray-900/10">
            <h2
              className={`${styles.heading2} bg-lightBlue-gradient`}
              // "text-2xl font-bold leading-10 tracking-tight text-gray-900"
            >
              Frequently asked questions
            </h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure key={faq.question} as="div" className="pt-6">
                  <dt>
                    <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900 font-poppins text-[18px] leading-[32px]">
                      {/* font-poppins font-normal text-[18px] leading-[32px] text-white my-10 */}
                      <span className="text-[18px] font-semibold leading-7">
                        {faq.question}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        <PlusIcon
                          aria-hidden="true"
                          className="h-5 w-5 group-data-[open]:hidden"
                        />
                        <MinusIcon
                          aria-hidden="true"
                          className="h-5 w-5 [.group:not([data-open])_&]:hidden"
                        />
                      </span>
                    </DisclosureButton>
                  </dt>
                  <DisclosurePanel as="dd" className="mt-2 pr-12">
                    <p className=" leading-7 text-gray-600 font-poppins text-[16px]">
                      {faq.answer}
                    </p>
                  </DisclosurePanel>
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
}
